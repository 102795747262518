import React, { FunctionComponent } from 'react';
import { Navbar, Nav, Media, Container } from 'react-bootstrap';

export const About : FunctionComponent = (props) => {
  return (
    <div className="main-section">
      <Container>
        <h2>METRHACK Team</h2>

        <Media>
          <img
            style={{
              width: 100,
              height: 100,
              borderRadius: 50,
              margin: 10
            }}
            src="/img/team/david.jpg"
          />
          <Media.Body>
        <h3>David Smith - Engineering Director</h3>

        <p>David Smith has over 12 years in design, manufacturing, and testing of
          rollingstock projects in both passenger and freight rollingstock.
          Having began his career at Downer and later Progress Rail a Caterpillar
          Company, David has worked across multiple regulartory authorities and
          with customers all across Australia.</p>
        <p>David has been awarded a Bachelor of Engineering with double major in
          Mechatronic from the University of Queensland, and a Graduate
          Certificate in Rollingstock Engineering from the University of
          Wollongong.</p>
        <p>David has developed complex desktop based and web based computer
          systems with a focus on enhancing process productivity, quality, and
          security.</p>
        </Media.Body>
      </Media>
      <Media>
        <img
          style={{
            width: 100,
            height: 100,
            borderRadius: 50,
            margin: 10
          }}
          src="/img/team/chiang.jpg"
        />
        <Media.Body>
        <h3>Chiang Lu - Technical Director</h3>

        <p>Chiang Lu has been awarded a Master of Philosophy within the field of
          machine learning from the University of Sydney, and a Bachelor of
          Engineering major in Electrical from the University of Sydney.</p>
        <p>Chiang's expertise and research focus has been applying machine
          learning and computer vision techniques to produce probabilistic
          forecasts of wind farm power outputs and electricity market spot
          price.</p>
        </Media.Body>
        </Media>
      </Container>
    </div>
  );
}

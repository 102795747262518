import React, { FunctionComponent, useState } from 'react';
import { Button, Row, Col, Container, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Contact : FunctionComponent = (props) => {
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ company, setCompany ] = useState('');
  const [ country, setCountry ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ newsletter, setNewsletter ] = useState(false);
  const [ submitted, setSubmitted ] = useState(false);

  const changeFirstName = (e) => {
    setFirstName(e.target.value);
  }

  const changeLastName = (e) => {
    setLastName(e.target.value);
  }

  const changeEmail = (e) => {
    setEmail(e.target.value);
  }

  const changePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  }

  const changeCompany = (e) => {
    setCompany(e.target.value);
  }

  const changeCountry = (e) => {
    setCountry(e.target.value);
  }

  const changeMessage = (e) => {
    setMessage(e.target.value);
  }

  const changeNewsletter = (e) => {
    setNewsletter(e.target.checked);
  }

  const submitForm = async (e) => {
    e.preventDefault();

    var contact = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      company: company,
      country: country,
      newsletter: newsletter,
      message: message
    };

    const response = await fetch(`https://vraptor.tech/api/users/contact`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(contact)
    });

    if (!response.ok) {
      setSubmitted(false);
    } else {
      setSubmitted(true);
    }
  }

  if (submitted) {
    return (
      <div className="main-section">
        <Container>
          <h2>Contact</h2>

          <p>Thank you for contacting us.</p>
        </Container>
      </div>
    );
  }

  return (
    <div className="main-section">
      <Container>
        <h2>Contact</h2>

        <p>Contact us to discuss your requirements and we will get back to you with a proposal, or a product demonstration.</p>

        <p>We are based in Sydney, Australia.</p>

        <Container fluid="md">
          <Row>
            <Col md style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <img style={{ width: 256 }} src="/mascot-binary.svg" />
            </Col>
            <Col>
        <Form onSubmit={submitForm}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>First name</Form.Label>
              <Form.Control type="text" placeholder="First name" value={firstName} onChange={changeFirstName} />
            </Form.Group>
            
            <Form.Group as={Col}>
              <Form.Label>Last name</Form.Label>
              <Form.Control type="text" placeholder="Last name" value={lastName} onChange={changeLastName} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>E-mail</Form.Label>
              <Form.Control type="email" placeholder="E-mail" value={email} onChange={changeEmail} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>E-mail</Form.Label>
              <Form.Control type="phone" placeholder="Phone number" value={phoneNumber} onChange={changePhoneNumber} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Company</Form.Label>
              <Form.Control type="text" placeholder="Company" value={company} onChange={changeCompany} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" placeholder="Country" value={country} onChange={changeCountry} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Message (optional)</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Include a message" value={message} onChange={changeMessage}></Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group>
              <Form.Switch id="newsletter" checked={newsletter} onChange={changeNewsletter} label="Sign up to the newsletter" />
            </Form.Group>
          </Form.Row>
          <p>Information submitted is subject to our <Link to="/privacy">Privacy Policy</Link> and <Link to="/tos">Terms</Link>.</p>
          <Button type="submit">Submit</Button>
        </Form>
        </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export { Contact };

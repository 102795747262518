import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap'
import { Projects, Services, About, Contact, Home, Privacy, Tos, Blog } from './pages';
import './App.css';

const App : FunctionComponent = (props) => {
  return (
    <Router>
      <header>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/">
            <img
              src="/logo.png"
              height="32"
              className="d-inline-block align-top"
              alt="METRHACK logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={NavLink} to="/">Home</Nav.Link>
              <Nav.Link as={NavLink} to="/projects">Projects</Nav.Link>
              <Nav.Link as={NavLink} to="/services">Services</Nav.Link>
              <Nav.Link as={NavLink} to="/about">About</Nav.Link>
              <Nav.Link as={NavLink} to="/contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
      <main role="main" className="flex-shrink-0">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/tos">
            <Tos />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
        </Switch>
      </main>
      <footer className="nav-footer mt-auto py-3" id="footer">
        <section className="sitemap">
          <Link className="nav-home" to="/">
            <img
              src="/mascot.svg"
              alt="Home"
              width="66"
              height="66"
            />
          </Link>
          <div>
            <h5>Community</h5>
            <a href="https://github.com/metrhack/">Github</a>
          </div>
          <div>
            <h5>Social</h5>
            <a href="https://www.linkedin.com/company/metrhack">LinkedIn</a>
            <a href="https://twitter.com/metrhack">Twitter</a>
            <a href="https://facebook.com/metrhack">Facebook</a>
            <a href="https://www.youtube.com/channel/UCbBilsvKfblgfQGp5fbXYNg">YouTube</a>
          </div>
          <div>
            <h5>More</h5>
            <a href="https://medium.com/metrhack">Blog</a>
            <Link to="/tos">Terms of Services</Link>
            <Link to="/privacy">Privacy</Link>
          </div>
        </section>


        <section className="copyright">&copy; 2021 METRHACK PTY LTD ABN 86 634 013 871</section>
      </footer>
    </Router>
  );
}

export default App;

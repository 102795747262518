import React from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';

function Projects() {
  return (
    <div className="main-section">
      <Container>
        <h2>Projects</h2>

        <p>Some of the projects we have worked on.</p>

        <Container>
          <Row>
            <Col md style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <a href="https://vraptor.tech/"><img src="v-raptor-gradient.svg" height={48} /></a>
            </Col>
            <Col>
              <p>V-Raptor is a systems engineering and project management platform.</p>

              <p>We aim to help engineering companies innovate on the way they
                deliver projects using systems engineering with this project.</p>
              
              <p>Using React Native allows us to build native experiences for the web,
                iOS, and Android using a single code base.</p>

              <p><a href="https://vraptor.tech/">Learn more...</a></p>
            </Col>
          </Row>
        </Container>

      </Container>
    </div>
  );
}

export { Projects };

import React, { FunctionComponent } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

function Tos(props) {
  return (
    <div className="main-section">
      <Container>
        <h2>Terms of service</h2>

        <h3>About the Application</h3>

        <p>Welcome to Metrhack (https://metrhack.com) (the 'Application'). The
          Application provides Computer Aided Systems Engineering as a service
          (the 'Services').</p>

        <p>The Application is operated by METRHACK PTY LTD (ACN 634013871).
          Access to and use of the Application, or any of its associated Products
          or Services, is provided by METRHACK. Please read these terms and
          conditions (the 'Terms') carefully. By using, browsing and/or reading
          the Application, this signifies that you have read, understood and
          agree to be bound by the Terms. If you do not agree with the Terms, you
          must cease usage of the Application, or any of its Services,
          immediately.</p>

        <p>METRHACK reserves the right to review and change any of the Terms by
          updating this page at its sole discretion. When METRHACK updates the
          Terms, it will use reasonable endeavours to provide you with notice of
          updates to the Terms. Any changes to the Terms take immediate effect
          from the date of their publication. Before you continue, we recommend
          you keep a copy of the Terms for your records.</p>

        <h3>Acceptance of the Terms</h3>

        <p>You accept the Terms by using or browsing the Application. You may
          also accept the Terms by clicking to accept or agree to the Terms
          where this option is made available to you by METRHACK in the user
          interface.</p>
        
        <p>Subscription to use the Services</p>

        <p>In order to access the Services, you must first purchase a
          subscription through the Application (the 'Subscription') and pay the
          applicable fee for the selected Subscription (the 'Subscription Fee').</p>

        <p>In purchasing the Subscription, you acknowledge and agree that it is
          your responsibility to ensure that the Subscription you elect to
          purchase is suitable for your use.</p>

        <p>Once you have purchased the Subscription, you will then be required to
          register for an account through the Application before you can access
          the Services (the 'Account').</p>

        <p>As part of the registration process, or as part of your continued use
          of the Services, you may be required to provide personal information
          about yourself (such as identification or contact details), including:</p>

        <p>Email address</p>

        <p>Preferred username</p>

        <p>Telephone number</p>

        <p>You warrant that any information you give to METRHACK in the course of
          completing the registration process will always be accurate, correct
          and up to date.</p>

        <p>Once you have completed the registration process, you will be a
          registered member of the Application ('Member') and agree to be bound
          by the Terms. As a Member you will be granted immediate access to the
          Services from the time you have completed the registration process
          until the subscription period expires (the 'Subscription Period').</p>

        <p>You may not use the Services and may not accept the Terms if:</p>

        <p>you are not of legal age to form a binding contract with METRHACK; or</p>

        <p>you are a person barred from receiving the Services under the laws of
          Australia or other countries including the country in which you are
          resident or from which you use the Services.</p>
        
        <h3>Your obligations as a Member</h3>

        <p>As a Member, you agree to comply with the following:</p>

        <p>you will use the Services only for purposes that are permitted by:</p>

        <p>the Terms; and</p>

        <p>any applicable law, regulation or generally accepted practices or
          guidelines in the relevant jurisdictions;</p>

        <p>you have the sole responsibility for protecting the confidentiality of
          your password and/or email address. Use of your password by any other
          person may result in the immediate cancellation of the Services;</p>

        <p>any use of your registration information by any other person, or third
          parties, is strictly prohibited. You agree to immediately notify
          METRHACK of any unauthorised use of your password or email address or
          any breach of security of which you have become aware;</p>

        <p>access and use of the Application is limited, non-transferable and
          allows for the sole use of the Application by you for the purposes of
          METRHACK providing the Services;</p> 

        <p>you will not use the Services or the Application in connection with
          any commercial endeavours except those that are specifically endorsed
          or approved by the management of METRHACK;</p>

        <p>you will not use the Services or Application for any illegal and/or
          unauthorised use which includes collecting email addresses of Members
          by electronic or other means for the purpose of sending unsolicited
          email or unauthorised framing of or linking to the Application;</p>

        <p>you agree that commercial advertisements, affiliate links, and other
          forms of solicitation may be removed from the Application without
          notice and may result in termination of the Services. Appropriate legal
          action will be taken by METRHACK for any illegal or unauthorised use of
          the Application; and</p>

        <p>you acknowledge and agree that any automated use of the Application or
          its Services is prohibited.</p>
        
        <h3>Payment</h3>

        <p>Where the option is given to you, you may make payment of the
          Subscription Fee by way of:</p>

        <p>Electronic funds transfer('EFT') into our nominated bank account</p>

        <p>Credit Card Payment ('Credit Card')</p>

        <p>All payments made in the course of your use of the Services are made
          using Stripe. In using the Application, the Services or when making any
          payment in relation to your use of the Services, you warrant that you
          have read, understood and agree to be bound by the POLI terms and
          conditions which are available on their Application.</p>

        <p>You acknowledge and agree that where a request for the payment of the
          Subscription Fee is returned or denied, for whatever reason, by your
          financial institution or is unpaid by you for any other reason, then
          you are liable for any costs, including banking fees and charges,
          associated with the Subscription Fee.</p>

        <p>You agree and acknowledge that METRHACK can vary the Subscription Fee
          at any time  and that the varied Subscription Fee will come into effect
          following the conclusion of the existing Subscription Period.</p>

        <h3>Refund Policy</h3>

        <p>METRHACK will only provide you with a refund of the Subscription Fee
          in the event they are unable to continue to provide the Services or if
          the manager of METRHACK makes a decision, at its absolute discretion,
          that it is reasonable to do so under the circumstances . Where this
          occurs, the refund will be in the proportional amount of the
          Subscription Fee that remains unused by the Member (the 'Refund').</p>
        
        <h3>Copyright and Intellectual Property</h3>

        <p>The Application, the Services and all of the related products of
          METRHACK are subject to copyright. The material on the Application is
          protected by copyright under the laws of Australia and through
          international treaties. Unless otherwise indicated, all rights
          (including copyright) in the Services and compilation of the
          Application (including but not limited to text, graphics, logos, button
          icons, video images, audio clips, Application, code, scripts, design
          elements and interactive features) or the Services are owned or
          controlled for these purposes, and are reserved by METRHACK or its
          contributors.</p>

        <p>All trademarks, service marks and trade names are owned, registered
          and/or licensed by METRHACK, who grants to you a worldwide,
          non-exclusive, royalty-free, revocable license whilst you are a Member
          to:</p>

        <p>use the Application pursuant to the Terms;</p>

        <p>copy and store the Application and the material contained in the
          Application in your device's cache memory; and</p>

        <p>print pages from the Application for your own personal and
          non-commercial use.</p>

        <p>METRHACK does not grant you any other rights whatsoever in relation
          to the Application or the Services. All other rights are expressly
          reserved by METRHACK.</p>

        <p>METRHACK retains all rights, title and interest in and to the
          Application and all related Services. Nothing you do on or in relation
          to the Application will transfer any:</p>

        <p>business name, trading name, domain name, trade mark, industrial
          design, patent, registered design or copyright, or</p>

        <p>a right to use or exploit a business name, trading name, domain
          name, trade mark or industrial design, or</p>

        <p>a thing, system or process that is the subject of a patent, registered
          design or copyright (or an adaptation or modification of such a thing,
          system or process),</p>

        <p>to you.</p>
        
        <p>You may not, without the prior written permission of METRHACK and the
          permission of any other relevant rights owners: broadcast, republish,
          up-load to a third party, transmit, post, distribute, show or play in
          public, adapt or change in any way the Services or third party Services
          for any purpose, unless otherwise provided by these Terms. This
          prohibition does not extend to materials on the Application, which are
          freely available for re-use or are in the public domain.</p>

        <h3>Privacy</h3>

        <p>METRHACK takes your privacy seriously and any information provided
          through your use of the Application and/or Services are subject to
          METRHACK's Privacy Policy, which is available at
          https://metrhack.com/privacy.</p>
        
        <h3>General Disclaimer</h3>

        <p>Nothing in the Terms limits or excludes any guarantees, warranties,
          representations or conditions implied or imposed by law, including the
          Australian Consumer Law (or any liability under them) which by law may
          not be limited or excluded.</p>

        <p>Subject to this clause, and to the extent permitted by law:</p>

        <p>all terms, guarantees, warranties, representations or conditions which
          are not expressly stated in the Terms are excluded; and</p>

        <p>METRHACK will not be liable for any special, indirect or consequential
          loss or damage (unless such loss or damage is reasonably foreseeable
          resulting from our failure to meet an applicable Consumer Guarantee),
          loss of profit or opportunity, or damage to goodwill arising out of or
          in connection with the Services or these Terms (including as a result
          of not being able to use the Services or the late supply of the
          Services), whether at common law, under contract, tort (including
          negligence), in equity, pursuant to statute or otherwise.</p>

        <p>Use of the Application and the Services is at your own risk.
          Everything on the Application and the Services is provided to you "as
          is" and "as available" without warranty or condition of any kind. None
          of the affiliates, directors, officers, employees, agents, contributors
          and licensors of METRHACK make any express or implied representation or
          warranty about the Services or any products or Services (including the
          products or Services of METRHACK) referred to on the Application. This
          includes (but is not restricted to) loss or damage you might suffer as
          a result of any of the following:</p>

        <p>failure of performance, error, omission, interruption, deletion,
          defect, failure to correct defects, delay in operation or transmission,
          computer virus or other harmful component, loss of data, communication
          line failure, unlawful third party conduct, or theft, destruction,
          alteration or unauthorised access to records;</p>

        <p>the accuracy, suitability or currency of any information on the
          Application, the Services, or any of its Services related products
          (including third party material and advertisements on the
          Application);</p>

        <p>costs incurred as a result of you using the Application, the Services
          or any of the products of METRHACK; and</p>

        <p>the Services or operation in respect to links which are provided for
          your convenience.</p>
        
        <h3>Competitors</h3>

        <p>If you are in the business of providing similar Services for the
          purpose of providing them to users for a commercial gain, whether
          business users or domestic users, then you are a competitor of
          METRHACK. Competitors are not permitted to use or access any
          information or content on our Application. If you breach this
          provision, METRHACK will hold you fully responsible for any loss that
          we may sustain and hold you accountable for all profits that you might
          make from such a breach.</p>

        <h3>Limitation of liability</h3>

        <p>METRHACK's total liability arising out of or in connection with the
          Services or these Terms, however arising, including under contract,
          tort (including negligence), in equity, under statute or otherwise,
          will not exceed the resupply of the Services to you.</p>

        <p>You expressly understand and agree that METRHACK, its affiliates,
          employees, agents, contributors and licensors shall not be liable to
          you for any direct, indirect, incidental, special consequential or
          exemplary damages which may be incurred by you, however caused and
          under any theory of liability. This shall include, but is not limited
          to, any loss of profit (whether incurred directly or indirectly), any
          loss of goodwill or business reputation and any other intangible
          loss.</p>

        <h3>Termination of Contract</h3>

        <p>The Terms will continue to apply until terminated by either you or by
          METRHACK as set out below.</p> 

        <p>If you want to terminate the Terms, you may do so by:</p>

        <p>not renewing the Subscription prior to the end of the Subscription
          Period;</p>

        <p>providing METRHACK with 30 days' notice of your intention to
          terminate; and</p>

        <p>closing your accounts for all of the services which you use, where
          METRHACK has made this option available to you.</p>

        <p>Your notice should be sent, in writing, to METRHACK via the 'Contact
          Us' link on our homepage.</p>

        <p>METRHACK may at any time, terminate the Terms with you if:</p>

        <p>you do not renew the Subscription at the end of the Subscription
          Period;</p>

        <p>you have breached any provision of the Terms or intend to breach any
          provision;</p> 

        <p>METRHACK is required to do so by law;</p>

        <p>the provision of the Services to you by METRHACK is, in the opinion of
          METRHACK, no longer commercially viable.</p>

        <p>Subject to local applicable laws, METRHACK reserves the right to
          discontinue or cancel your membership at any time and may suspend or
          deny, in its sole discretion, your access to all or any portion of the
          Application or the Services without notice if you breach any provision
          of the Terms or any applicable law or if your conduct impacts
          METRHACK's name or reputation or violates the rights of those of
          another party.</p>
        
        <h3>Indemnity</h3>

        <p>You agree to indemnify METRHACK, its affiliates, employees, agents,
          contributors, third party content providers and licensors from and
          against:</p>

        <p>all actions, suits, claims, demands, liabilities, costs, expenses,
          loss and damage (including legal fees on a full indemnity basis)
          incurred, suffered or arising out of or in connection with Your
          Content;</p>

        <p>any direct or indirect consequences of you accessing, using or
          transacting on the Application or attempts to do so; and/or</p>

        <p>any breach of the Terms.</p>

        <h3>Severance</h3>

        <p>If any part of these Terms is found to be void or unenforceable by a
          Court of competent jurisdiction, that part shall be severed and the
          rest of the Terms shall remain in force.</p>
      </Container>
    </div>
  );
}

export { Tos };

import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

function Blog() {
  return (
    <div className="main-section">
      <Container>
        <h2>Blog</h2>

      </Container>
    </div>
  );
}

export { Blog };

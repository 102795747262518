import React, { FunctionComponent } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

function Privacy(props) {
  return (
    <div className="main-section">
      <Container>
        <h2>Privacy policy</h2>

        <h3>We respect your privacy</h3>

        <p>METRHACK PTY LTD respects your right to privacy and is committed to
          safeguarding the privacy of our customers and website visitors. We
          adhere to the Australian Privacy Principles contained in the Privacy
          Act 1988 (Cth). This policy sets out how we collect and treat your
          personal information.</p>
        
        <p>"Personal information" is information we hold which is identifiable as
          being about you.</p>
        
        <h3>Collection of personal information</h3>

        <p>METRHACK PTY LTD will, from time to time, receive and store personal
          information you enter onto our website, provided to us directly or
          given to us in other forms.</p>

        <p>You may provide basic information such as your name, phone number,
          address and email address to enable us to send information, provide
          updates and process your product or service order. We may collect
          additional information at other times, including but not limited to,
          when you provide feedback, when you provide information about your
          personal or business affairs, change your content or email preference,
          respond to surveys and/or promotions, provide financial or credit card
          information, or communicate with our customer support.</p>

        <p>Additionally, we may also collect any other information you provide
          while interacting with us.</p>
        
        <h3>How we collect your personal information</h3>

        <p>METRHACK PTY LTD collects personal information from you in a variety
          of ways, including when you interact with us electronically or in
          person, when you access our website and when we provide our services to
          you. We may receive personal information from third parties. If we do,
          we will protect it as set out in this Privacy Policy.</p>
        
        <h3>Use of your personal information</h3>

        <p>METRHACK PTY LTD may use personal information collected from you to
          provide you with information, updates and our services. We may also
          make you aware of new and additional products, services and
          opportunities available to you. We may use your personal information to
          improve our products and services and better understand your needs.</p>

        <p>METRHACK PTY LTD may contact you by a variety of measures including,
          but not limited to telephone, email, sms or mail.</p>

        <h3>Disclosure of your personal information</h3>

        <p>We may disclose your personal information to any of our employees,
          officers, insurers, professional advisers, agents, suppliers or
          subcontractors insofar as reasonably necessary for the purposes set out
          in this Policy. Personal information is only supplied to a third party
          when it is required for the delivery of our services.</p>

        <p>We may from time to time need to disclose personal information to
          comply with a legal requirement, such as a law, regulation, court
          order, subpoena, warrant, in the course of a legal proceeding or in
          response to a law enforcement agency request.</p>

        <p>We may also use your personal information to protect the copyright,
          trademarks, legal rights, property or safety of METRHACK PTY LTD,
          https://metrhack.com, its customers or third parties.</p>

        <p>Information that we collect may from time to time be stored, processed
          in or transferred between parties located in countries outside of
          Australia.</p>

        <p>If there is a change of control in our business or a sale or transfer
          of business assets, we reserve the right to transfer to the extent
          permissible at law our user databases, together with any personal
          information and non-personal information contained in those databases.
          This information may be disclosed to a potential purchaser under an
          agreement to maintain confidentiality. We would seek to only disclose
          information in good faith and where required by any of the above
          circumstances.</p>

        <p>By providing us with personal information, you consent to the terms of
          this Privacy Policy and the types of disclosure covered by this Policy.
          Where we disclose your personal information to third parties, we will
          request that the third party follow this Policy regarding handling your
          personal information.</p>
        
        <h3>Security of your personal information</h3>

        <p>METRHACK PTY LTD is committed to ensuring that the information you
          provide to us is secure. In order to prevent unauthorised access or
          disclosure, we have put in place suitable physical, electronic and
          managerial procedures to safeguard and secure information and protect
          it from misuse, interference, loss and unauthorised access,
          modification and disclosure.</p>
        
        <p>The transmission and exchange of information is carried out at your
          own risk. We cannot guarantee the security of any information that you
          transmit to us, or receive from us. Although we take measures to
          safeguard against unauthorised disclosures of information, we cannot
          assure you that personal information that we collect will not be
          disclosed in a manner that is inconsistent with this Privacy Policy.</p>
        
        <h3>General Data Protection Regulation (GDPR) for the European Union (EU)</h3>

        <p>METRHACK PTY LTD will comply with the principles of data protection
          set out in the GDPR for the purpose of fairness, transparency and
          lawful data collection and use.</p>

        <p>We process your personal information as a Processor and/or to the
          extent that we are a Controller as defined in the GDPR.</p>

        <p>We must establish a lawful basis for processing your personal
          information. The legal basis for which we collect your personal
          information depends on the data that we collect and how we use it.</p>

        <p>We will only collect your personal information with your express
          consent for a specific purpose and any data collected will be to the
          extent necessary and not excessive for its purpose. We will keep your
          data safe and secure.</p>

        <p>We will also process your personal information if it is necessary for
          our legitimate interests, or to fulfil a contractual or legal
          obligation.</p>

        <p>We process your personal information if it is necessary to protect
          your life or in a medical situation, it is necessary to carry out a
          public function, a task of public interest or if the function has a
          clear basis in law.</p>

        <p>We do not collect or process any personal information from you that is
          considered "Sensitive Personal Information" under the GDPR, such as
          personal information relating to your sexual orientation or ethnic
          origin unless we have obtained your explicit consent, or if it is being
          collected subject to and in accordance with the GDPR.</p>

        <p>You must not provide us with your personal information if you are
          under the age of 16 without the consent of your parent or someone who
          has parental authority for you. We do not knowingly collect or process
          the personal information of children.</p>
        
        <h3>Your rights under the GDPR</h3>

        <p>If you are an individual residing in the EU, you have certain rights
          as to how your personal information is obtained and used. METRHACK PTY
          LTD complies with your rights under the GDPR as to how your personal
          information is used and controlled if you are an individual residing in
          the EU</p>

        <p>Except as otherwise provided in the GDPR, you have the following
          rights:</p>

        <p>to be informed how your personal information is being used;</p>

        <p>access your personal information (we will provide you with a free copy
          of it);</p>

        <p>to correct your personal information if it is inaccurate or
          incomplete;</p>

        <p>to delete your personal information (also known as "the right to be
          forgotten");</p>

        <p>to restrict processing of your personal information;</p>

        <p>to retain and reuse your personal information for your own purposes;</p>

        <p>to object to your personal information being used; and</p>

        <p>to object against automated decision making and profiling.</p>

        <p>Please contact us at any time to exercise your rights under the GDPR
          at the contact details in this Privacy Policy.</p>

        <p>We may ask you to verify your identity before acting on any of your
          requests.</p>

        <h3>Hosting and International Data Transfers</h3>

        <p>Information that we collect may from time to time be stored, processed
          in or transferred between parties or sites located in countries outside
          of Australia. These may include, but are not limited to Australia.</p>

        <p>We and our other group companies have offices and/or facilities in
          Australia. Transfers to each of these countries will be protected by
          appropriate safeguards, these include one or more of the following:
          the use of standard data protection clauses adopted or approved by the
          European Commission which you can obtain from the European Commission
          Website; the use of binding corporate rules, a copy of which you can
          obtain from METRHACK PTY LTD's Data Protection Officer.</p>

        <p>The hosting facilities for our website are situated in Australia.
          Transfers to each of these Countries will be protected by appropriate
          safeguards, these include one or more of the following: the use of
          standard data protection clauses adopted or approved by the European
          Commission which you can obtain from the European Commission Website;
          the use of binding corporate rules, a copy of which you can obtain from
          METRHACK PTY LTD's Data Protection Officer.</p>

        <p>Our Suppliers and Contractors are situated in Australia. Transfers to
          each of these Countries will be protected by appropriate safeguards,
          these include one or more of the following: the use of standard data
          protection clauses adopted or approved by the European Commission which
          you can obtain from the European Commission Website; the use of binding
          corporate rules, a copy of which you can obtain from METRHACK PTY LTD's
          Data Protection Officer.</p>

        <p>You acknowledge that personal data that you submit for publication
          through our website or services may be available, via the internet,
          around the world. We cannot prevent the use (or misuse) of such
          personal data by others.</p>
        
        <h3>Security of your personal information</h3>

        <p>METRHACK PTY LTD is committed to ensuring that the information you
          provide to us is secure. In order to prevent unauthorised access or
          disclosure, we have put in place suitable physical, electronic and
          managerial procedures to safeguard and secure information and protect
          it from misuse, interference, loss and unauthorised access,
          modification and disclosure.</p>

        <p>Where we employ data processors to process personal information on our
          behalf, we only do so on the basis that such data processors comply
          with the requirements under the GDPR and that have adequate technical
          measures in place to protect personal information against unauthorised
          use, loss and theft.</p>

        <p>The transmission and exchange of information is carried out at your
          own risk. We cannot guarantee the security of any information that you
          transmit to us, or receive from us. Although we take measures to
          safeguard against unauthorised disclosures of information, we cannot
          assure you that personal information that we collect will not be
          disclosed in a manner that is inconsistent with this Privacy Policy.</p>
        
        <h3>Access to your personal information</h3>

        <p>You may request details of personal information that we hold about you
          in accordance with the provisions of the Privacy Act 1988 (Cth). A
          small administrative fee may be payable for the provision of
          information. If you would like a copy of the information, which we hold
          about you or believe that any information we hold on you is inaccurate,
          out of date, incomplete, irrelevant or misleading, please email us at
          info@metrhack.com.</p>

        <p>We reserve the right to refuse to provide you with information that we
          hold about you, in certain circumstances set out in the Privacy Act.</p>
        
        <h3>Complaints about privacy</h3>

        <p>If you have any complaints about our privacy practices, please feel
          free to send in details of your complaints to
          2 397 Riley Street, Surry Hills, New South Wales, 2010.
          We take complaints very seriously and will respond shortly after
          receiving written notice of your complaint.</p>
        
        <h3>Changes to Privacy Policy</h3>

        <p>Please be aware that we may change this Privacy Policy in the future.
          We may modify this Policy at any time, in our sole discretion and all
          modifications will be effective immediately upon our posting of the
          modifications on our website or notice board. Please check back from
          time to time to review our Privacy Policy.</p>
        
        <h3>Website</h3>

        <h4>When you visit our website</h4>

        <p>When you come to our website (https://metrhack.com) we may collect
          certain information such as browser type, operating system, website
          visited immediately before coming to our site, etc. This information is
          used in an aggregated manner to analyse how people use our site, such
          that we can improve our service.</p>
        
        <h4>Cookies</h4>

        <p>We may from time to time use cookies on our website. Cookies are very
          small files which a website uses to identify you when you come back to
          the site and to store details about your use of the site. Cookies are
          not malicious programs that access or damage your computer. Most web
          browsers automatically accept cookies but you can choose to reject
          cookies by changing your browser settings. However, this may prevent
          you from taking full advantage of our website. Our website may from
          time to time use cookies to analyses website traffic and help us
          provide a better website visitor experience. In addition, cookies may
          be used to serve relevant ads to website visitors through third party
          services such as Google Adwords. These ads may appear on this website
          or other websites you visit.</p>
        
        <h4>Third party sites</h4>

        <p>Our site may from time to time have links to other websites not owned
          or controlled by us. These links are meant for your convenience only.
          Links to third party websites do not constitute sponsorship or
          endorsement or approval of these websites. Please be aware that
          METRHACK PTY LTD is not responsible for the privacy practises of other
          such websites. We encourage our users to be aware, when they leave our
          website, to read the privacy statements of each and every website that
          collects personal identifiable information.</p>
      </Container>
    </div>
  );
}

export { Privacy };

import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

function Services() {
  return (
    <div className="main-section">
      <Container>
        <h2>Services</h2>
        <h3>App development</h3>

        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20,
          marginBottom: 20,
          WebkitMaskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))'
        }}>
          <img style={{
            maxWidth: '100%',
            width: 1049
          }} src="/vscode.png" />
        </div>

        <p>Bring innovation to your organisation with apps. We can advise on the
          best type of app for the use case. There are different kinds of apps
          possible for every budget.</p>
        
        <ul>
          <li>Command line scripts</li>
          <li>Web based apps</li>
          <li>Mobile apps</li>
          <li>Microservices</li>
        </ul>

        <p>We can also deliver apps using many technologies to suit the use case:</p>

        <ul>
          <li>C#</li>
          <li>Typescript</li>
          <li>Python</li>
          <li>MATLAB</li>
          <li>PHP</li>
          <li>SQL</li>
          <li>HTML &amp; CSS</li>
          <li>React</li>
          <li>React Native</li>
          <li>Tensorflow</li>
        </ul>

        <p>We can advise engineering companies to develop processes to manage
          software assets produced by their engineers. As python and other
          scripting languages become more common within engineering
          organisations, the need to manage and maintain these assets increases
          as they are increasingly used to perform critical tasks to improve
          productivity.</p>

        <h3>Systems engineering</h3>

        <p>Bring success to multi-disciplinary engineering projects of all sizes
          with systems engineering. A right-sized systems engineering approach
          can be developed for engineering projects of all sizes.</p>
        
        <ul>
          <li>Systems engineering management plan</li>
          <li>Concept of operations</li>
          <li>Requirements engineering</li>
          <li>Verification plans</li>
          <li>Requirements verification traceability matrix</li>
        </ul>

        <h3>Test engineering</h3>

        <p>Mobilising the testing and evaluation phase of an engineering project
          has many requirements. We can advised on how best to mobilise the
          testing phase of your project.</p>
        
        <ul>
          <li>Test planning</li>
          <li>Test procedure development</li>
          <li>Tooling and calibration management</li>
          <li>Test witnessing</li>
        </ul>
      </Container>
    </div>
  );
}

export { Services };

import React, { FunctionComponent } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';

function Home(props) {
  return (<>
    <div className="main-section">

      <Container fluid="md">
        <Row>
          <Col>
            <h1>Engineering software for use by engineers</h1>

            <p>We are a team of professional engineers who obsess over the details that makes software work for engineers.</p>
          </Col>
          <Col md style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img style={{ width: 256 }} src="/mascot.svg" />
          </Col>
        </Row>
      </Container>
    </div>
    <div className="main-section-blue">
      <Container fluid="md">
        <Row className="flex-wrap-reverse">
          <Col md style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img style={{ width: 128 }} src="/innovation.svg" />
          </Col>
          <Col>
            <h2 className="white">Innovation engineering</h2>

            <p>Our projects transform the the way engineers work and bring
              value to our clients. We push boundaries where value can be
              realised and develop solutions that are maintainable.</p>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="main-section">
      <Container fluid="md">
        <Row>
          <Col>
            <h2>Systems thinking</h2>

            <p>We approach engineering projects from a systems perspective. We
              don't just look at problems in isolation, but as a part of a
              system. We consider how our solutions interface with other
              systems and people.
            </p>
          </Col>
          <Col md style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img style={{ width: 128 }} src="/systems.svg" />
          </Col>
        </Row>
      </Container>
    </div>
    <div className="main-section-purple">
      <Container fluid="md">
        <Row className="flex-wrap-reverse">
          <Col md style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img style={{ width: 256 }} src="/intelligence.svg" />
          </Col>
          <Col>
            <h2 className="white">Engineering intelligence</h2>

            <p>We re-imagine engineering through intelligent design. Whether
              implementing best practices, or introducing machine learning,
              we are capable of adding intelligence to engineering.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="main-section">
      <Container fluid="md">
        <Row>
          <Col>
            <h2 className="purple">Digital transformation</h2>

            <p>Transform your engineering processes by understanding what data
              you have, and identify additional data you need to extract
              information and distill knowledge to deliver improvement.
            </p>
          </Col>
          <Col md style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img style={{ width: 256 }} src="/transformation.svg" />
          </Col>
        </Row>
      </Container>
    </div>
  </>);
}

export { Home };
